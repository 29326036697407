import { useState, useEffect } from "react";
import routes from "./routes";
import App from "../../App";

function MyRouter() {
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => setPath(window.location.pathname);
    window.addEventListener("popstate", handleRouteChange);

    return () => window.removeEventListener("popstate", handleRouteChange);
  }, []);

  const currentRoute = Object.values(routes).find(
    (route) => route.path === path
  );

  if (!currentRoute) {
    return <h1>404 Not Found</h1>;
  }

  const ViewComponent = currentRoute.component;

  return (
    <App>
      <ViewComponent />
    </App>
  );
}

export default MyRouter;
