import Home from "../../views/Home";
import AboutUs from "../../views/AboutUs";
import Services from "../../views/Services";

const routes = {
  Home: {
    path: "/",
    component: Home,
  },
  AboutUs: {
    path: "/about-us",
    component: AboutUs,
  },
  Services: {
    path: "/services",
    component: Services,
  },
};

export default routes;
