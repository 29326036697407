import { useState, useEffect } from 'react';
import '../App.css';

function Home() {
  // Estado para manejar el texto de loading
  const [loadingText, setLoadingText] = useState('|');

  // Función para cambiar el valor de loading
  const changeLoading = (value) => {
    let newValue;
    if (value === "|") {
      newValue = "/";
    } else if (value === "/") {
      newValue = "—";
    } else if (value === "—") {
      newValue = "\\";
    } else {
      newValue = "|";
    }
    setLoadingText(newValue);
  };

  // useEffect para iniciar el bucle de cambio del texto cuando el componente se monta
  useEffect(() => {
    const interval = setInterval(() => {
      changeLoading(loadingText);
    }, 200);
    
    // Limpiar el intervalo cuando el componente se desmonta
    return () => clearInterval(interval);
  }, [loadingText]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Lizz Real Estate</h1>
        <h2>coming soon...</h2>
        {/* Mostramos el texto del estado en el span */}
        <span className="loading">{loadingText}</span>
      </header>
    </div>
  );
}

export default Home;