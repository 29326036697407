// Navbar.js
import React from "react";
import "./Navbar.css";

function Navbar({ selectedPage, setSelectedPage }) {
  return (
    <div className="navbar">
      <a href={"/"}>Hola</a>
      <a href={"/about-us"}>About Us</a>
      <a href={"/services"}>Services</a>
    </div>
  );
}

export default Navbar;
