import React, { useState } from "react";

function App({ children }) {
  return (
    <div className="App">
      <header className="App-header">{children}</header>
    </div>
  );
}

export default App;
